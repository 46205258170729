import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu, Icon} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const { SubMenu } = Menu;
class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (<Auxiliary>

        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">
               <Menu.Item key="dashboard">
                <Link to="/dashboard"><i className="icon icon-dasbhoard"/>
                  <IntlMessages id="sidebar.dashboard"/></Link>
              </Menu.Item>
              <SubMenu key="user" title={<span><i className="icon icon-user-o"/></span>}>
                <Menu.Item key="userForm"><Link to="/users"><Icon type="user" style={{fontSize: '20px'}}/>Users</Link></Menu.Item>
                <Menu.Item key="affiliate"><Link to="/affiliate"><Icon type="team" style={{fontSize: '20px'}}/>Affiliates</Link></Menu.Item>
                <Menu.Item key="activity"><Link to="/users-activity"><Icon type="book" style={{fontSize: '20px'}}/>User Activity Logs</Link></Menu.Item>
                <Menu.Item key="plActivity"><Link to="/pl-activity"><Icon type="book" style={{fontSize: '20px'}}/>PL Activity Logs</Link></Menu.Item>
                <Menu.Item key="qa"><Link to="/qa"><Icon type="file-search" style={{fontSize: '20px'}}/>QA Leads</Link></Menu.Item>
              </SubMenu>
              <Menu.Item key="potential-leads">
                <Link to="/potential-leads"><i className="icon icon-card"/>
                  <IntlMessages id="sidebar.leads"/></Link>
              </Menu.Item>
              <Menu.Item key="chats">
              <Link to="/chats"><i className="icon icon-chat"/>
                <IntlMessages id="sidebar.chats"/></Link>
              </Menu.Item>
              <Menu.Item key="group">
                <Link to="/groups"><i className="icon icon-callout"/>
                  <IntlMessages id="sidebar.groups"/></Link>
              </Menu.Item>
              <Menu.Item key="billing">
                <Link to="/billing"><i className="icon icon-files"/>
                  <IntlMessages id="sidebar.billing"/></Link>
              </Menu.Item>
              <Menu.Item key="gmb-setting">
                <Link to="/gmb-setting"><i className="icon icon-all-contacts"/>
                  <IntlMessages id="sidebar.gmb"/></Link>
              </Menu.Item>
              <SubMenu key="mca" title={<span><Icon type="phone" style={{fontSize: '20px'}}/></span>}>
                <Menu.Item key="customers"><Link to="/mca"><Icon type="user" style={{fontSize: '20px'}}/>Customers</Link></Menu.Item>
                <Menu.Item key="callalerts"><Link to="/callalerts"><Icon type="phone" style={{fontSize: '20px'}}/>Call Alerts</Link></Menu.Item>
              </SubMenu>
            </Menu>
          </CustomScrollbars>
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile/>
          </div>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  return {navStyle, themeType, locale, pathname}
};
export default connect(mapStateToProps)(SidebarContent);

